body {
  margin: 0;
  padding: 0;
  font-family: Proxima Nova, sans-serif;
  box-sizing: content-box;
  width: 100%;
  background-color: white;
}

p{
  margin: 0;
}

@font-face {
  font-family: "Proxima Nova";
  src: url('./ProximaNova-Regular.ttf') format("truetype");
}

@font-face {
  font-family: "Proxima Nova Bold";
  src: url('./ProximaNova-Bold.ttf') format("truetype");
}

@media (max-width: 1000px) {
  body {
    background-image: linear-gradient(160deg,
      #FF6567,
      #FF6567 5%,
      #E9595B 5%,
      #E9595B 10%,
      #FF6567 10%,
      #FF6567 15%,
      #E9595B 15%,
      #E9595B 20%,
      #FF6567 20%,
      #FF6567 25%,
      #E9595B 25%,
      #E9595B 30%,
      #FF6567 30%,
      #FF6567 35%,
      #E9595B 35%,
      #E9595B 40%,
      #FF6567 40%,
      #FF6567 45%,
      #E9595B 45%,
      #E9595B 50%,
      #FF6567 50%,
      #FF6567 55%,
      #E9595B 55%,
      #E9595B 60%,
      #FF6567 60%,
      #FF6567 65%,
      #E9595B 65%,
      #E9595B 70%,
      #FF6567 70%,
      #FF6567 75%,
      #E9595B 75%,
      #E9595B 80%,
      #FF6567 80%,
      #FF6567 85%,
      #E9595B 85%,
      #E9595B 90%,
      #FF6567 90%,
      #FF6567 95%,
      #E9595B 95%
    );
  }
}
